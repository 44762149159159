import React, { useEffect, useState } from 'react'
import {Link, useParams, useLocation, useHistory} from "react-router-dom"
import Orders from '../../components/orders/index'
import OrderDetail from '../../components/orders/detail'
import { updateProfile, generateDownloadLinkByInvoice, getFeaturedProducts, getPageBySlug } from '../../services/Apis'
import PreLoader from '../../components/preloader'
import DashboardFeaturedProducts from '../../components/dashboard-featured-products'
import ProgressBar from '../../components/progress-bar'

function MyAccount() {

    // const rightBanner = [
    //     'https://99designs-blog.imgix.net/blog/wp-content/uploads/2019/08/f9af26d7-abf3-46b6-b3d5-c0dda0a9ce2a-e1566928476465.jpg',
    //     'https://www.designwizard.com/wp-content/uploads/2021/02/f8205de0-66fa-11eb-bfc9-79c03e980cb6.jpg',
    //     'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTh2QVL2MAWE6b-CBlmiTmdT_rQn9uRQVG6-6n8Yg2gmPGieSn226ALWabHWe4VJn2O8Q&usqp=CAU',
    //     'https://i1.wp.com/www.smileycat.com/wp-content/uploads/2018/10/rawg-banner-ad-vertical.png'
    // ]

    const [completed, setCompleted] = useState(0);

    const history = useHistory()
    const urlParams = useParams()
    const search = useLocation().search;
    const orderId = new URLSearchParams(search).get('order');
    const orderDownloaded = new URLSearchParams(search).get('c');
    const loggedInUserName = (localStorage.user_data && JSON.parse(localStorage.user_data).firstname && JSON.parse(localStorage.user_data).lastname ? JSON.parse(localStorage.user_data).firstname+' '+JSON.parse(localStorage.user_data).lastname : '')
    const [profile, setProfile] = useState({})
    const [formErrorMsg, setFormErrorMsg] = useState('')
    const [downloadTimer, setDownloadTimer] = useState('')
    const [featuredProducts, setFeaturedProducts] = useState([])
    const [loadingFeaturedProducts, setLoadingFeaturedProducts] = useState(true)
    const [pageDetail, setPageDetail] = useState([]);
    const location = useLocation();

    const getPageDetail = () => {
        let slug = urlParams.id;
        console.error('slug ', (slug === 'dashboard'))
        if(slug === 'dashboard'){
            getPageBySlug(slug).then((result) => {
                if (result && result.code === 200 && result.data && result.data[0]) {
                    setPageDetail(result.data[0]);
                    // setDeviceDetailMeta(result.meta);
                    console.error("RESULT => ", result.data);
                }
            });
        }
    };

    useEffect(() => {
        // checkUsedrLoggedIn()
        getPageDetail();
        setProfile(JSON.parse(localStorage.user_data))
        getFeaturedProducts(200).then(result => {
            if(result && result.code === 200 && result.data){
                setFeaturedProducts(result.data)
                setLoadingFeaturedProducts(false)
            }
        })
        if(urlParams.id=='dashboard' && (orderId != null && orderId >0)){
            // console.error('INIT timer')
            initTimer(120, orderId) //60seconds *5 = 300sec(5min)
            progressTimer(120)
        }
    }, [location])

    const progressTimer = (maxSeconds) => {
        let seconds = maxSeconds;
        let bar = 0;
        let id = setInterval(function(){
            bar = bar + 1;
            setCompleted( bar );
            if (bar >=100){
                clearInterval(id);
            }
        }, seconds * 1000 / 100)
    }
    const initTimer = (duration, orderId) => {
        let timer = duration, minutes, seconds
        let secTimer = setInterval(function () {
            minutes = parseInt(timer / 60, 10)
            seconds = parseInt(timer % 60, 10)
    
            minutes = minutes < 10 ? "0" + minutes : minutes
            seconds = seconds < 10 ? "0" + seconds : seconds
            
            // console.error(minutes + ":" + seconds)
            setDownloadTimer(minutes + ":" + seconds)
            
            --timer
            if (timer < 0) {
                clearInterval(secTimer)
                showDownloadCompletion(orderId)
            }
        }, 1000)
    }

    const showDownloadCompletion = (orderId) => {
        //Send email API here
        generateDownloadLinkByInvoice({'invoice': orderId}).then(
            res => {
                // console.error('RESPONSE =>', res)
                if( res && res.code == 200 && res.success ){
                    history.push("/my-account/dashboard?c=1")
                } else {
                    alert(res.message)
                }
            }
        )
        
    }

    // const checkUserLoggedIn = () => {
    //     if (localStorage.access_token==undefined || localStorage.access_token == '') {
    //         history.push("/login");
    //     }
    // }

    const handleLogout = () => {
        localStorage.clear()
        // history.push('/')
        window.location = ''
    }

    const handleFormSubmission = event => {
        event.preventDefault()
        const elem = event.target.elements;
        
        // console.error(elem.firstname.value, elem.lastname.value, elem.account_email.value)
        if (elem.firstname.value == '' && elem.lastname.value == '' && elem.account_email.value == '') {
            setFormErrorMsg("Please provide required fields");
            return;
        }

        if(elem.password_1.value!='' && elem.password_2.value==''){
            setFormErrorMsg("Please provide New and Confirm password");
            return;
        } else if(elem.password_2.value!='' && elem.password_1.value==''){
            setFormErrorMsg("Please provide New and Confirm password");
            return;
        }

        if(elem.password_1.value!=elem.password_2.value){
            setFormErrorMsg("New and Confirm password should match");
            return;
        }


        if (elem.firstname.value != '' && elem.lastname.value != '' && elem.account_email.value != '') {

            let data = { 
                'firstname': elem.firstname.value, 
                'lastname': elem.lastname.value
            }
            if(elem.password_1.value && elem.password_1.value!=''){
                data.password = elem.password_1.value
            }
            updateProfile(data).then(
                res => {
                    // console.error('RESPONSE =>', res) //return;
                    if( res && res.code == 200 && res.success ){
                        // history.push("/")
                        setFormErrorMsg(res.message)
                        setTimeout(function(){
                            setFormErrorMsg('')
                        }, 2500)
                    } else {
                        console.error(res.message)
                    }
                }
            )
        }
    }

    const onFieldChange = event =>{
        const {name, value} = event.target;
        setProfile({...profile, [name]: value})
    }

    return (
        
        <div className="my-account-page-area">
            <div className="container">               
                <div className="woocommerce">               
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <nav className="woocommerce-MyAccount-navigation">
                                <ul>
                                    <li className={urlParams.id=='dashboard' ? ' active' : 'woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--dashboard' }>
                                        <Link className="woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--dashboard" exact={true} to='/my-account/dashboard'>
                                            Dashboard
                                        </Link>
                                    </li>
                                    <li className={urlParams.id=='profile' ? 'woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--dashboard active' : 'woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--dashboard' }>
                                        <Link className="woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--dashboard" exact={true} to='/my-account/profile'>
                                            Profile
                                        </Link>
                                    </li>
                                    <li className={urlParams.id=='orders' ? 'woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--dashboard active' : 'woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--dashboard' }>
                                        <Link className="woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--dashboard" exact={true} to='/my-account/orders'>
                                            Orders
                                        </Link>
                                    </li>
                                    <li className="woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--dashboard">
                                        <Link onClick={() => handleLogout()}>Logout</Link>
                                    </li>
                                    
                                    {/* <li className="woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--dashboard"><a href="#details" data-toggle="tab" aria-expanded="false">Account Details</a></li> */}

                                </ul>
                            </nav>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12" style={{'padding-left': '5px'}}> 
                            
                            <div className="tab-content">
                                <div className={urlParams.id=='dashboard' ? 'tab-pane fade active in' : 'tab-pane fade'} id="dashboard">
                                    <p className="dashboard-heading">
                                        Dashboard
                                    </p>
                                    <div className="woocommerce-MyAccount-content">    
                                        <p><strong>Hi  {loggedInUserName}.</strong></p>
                                        {urlParams.id=='dashboard' && (orderId == null || !orderId) && (orderDownloaded == null || !orderDownloaded) && 
                                            <p>From your account dashboard you can view your <b>recent orders</b> and manage <b>your password and account details</b>.</p>
                                        }
                                        {urlParams.id=='dashboard' && (orderId != null && orderId >0) &&
                                            <>
                                                <p className="blu-color">
                                                    <strong>Your order has been placed. We are processing your Document.</strong>
                                                </p>
                                                {/* <p className="blu-color time-remaining">
                                                    <strong>Time remaining: {downloadTimer}</strong>
                                                </p> */}
                                                <ProgressBar completed={completed} />
                                                <p className="dont-leave">
                                                    <strong>NOTE: DO NOT CLOSE this Document as we are processing your Document.</strong>
                                                </p>
                                            </>
                                        }
                                        
                                        {urlParams.id==='dashboard' && (orderDownloaded != null && orderDownloaded ==1) &&
                                            <p className="blu-color"><strong>Your Document is processed successfuly. Please check your Email for the Download Link.</strong></p>
                                        }
                                        
                                    </div>
                                    
                                    <div className="row dashboarder inner-section-space-top">
                                        <div className="col-lg-9 col-md-9">
                                            <div className="tab-content">
                                                <div role="tabpanel" className="tab-pane active clear products-container" id="list-view">
                                                
                                                {   loadingFeaturedProducts ?
                                                    <>
                                                        <div className="col-lg-12 col-md-12 col-sm-4 col-xs-12" style={{'padding':'0'}}>
                                                            <h3 className="dashboard-heading">
                                                                Featured Products
                                                            </h3>    
                                                        </div>
                                                        <div className="row featuredContainer text-center">
                                                            <PreLoader/>
                                                        </div>
                                                    </>
                                                    :
                                                    featuredProducts.length>0 &&
                                                    
                                                        <>
                                                            <div className="col-lg-12 col-md-12 col-sm-4 col-xs-12" style={{'padding':'0'}}>
                                                                <h3 className="dashboard-heading">
                                                                    Featured Products
                                                                </h3>    
                                                            </div>
                                                            <DashboardFeaturedProducts data={featuredProducts} />
                                                        </>
                                                    
                                                }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 stick" id="sticker">
                                            {pageDetail.banner_url && pageDetail.banner_url !== null && pageDetail.offer_button_url && pageDetail.offer_button_url!=null &&
                                                <a href={pageDetail.offer_button_url && pageDetail.offer_button_url!=null ? pageDetail.offer_button_url : 'javascript:void(0)'} target="_blank">
                                                    <img src={"https://www.ezowotools.com/adminpanel/image/catalog/banners/" +pageDetail.banner_url} />
                                                </a>
                                            }
                                            {pageDetail.banner_url && pageDetail.banner_url !== null && (pageDetail.offer_button_url===null || pageDetail.offer_button_url==='') &&
                                                <a href="javascript:void(0)" target="_self">
                                                    <img src={"https://www.ezowotools.com/adminpanel/image/catalog/banners/" +pageDetail.banner_url} />
                                                </a>
                                            }
                                            
                                        </div>
                                    </div>

                                </div> 
                                <div className={urlParams.id=='orders' ? 'tab-pane fade active in' : 'tab-pane fade'} id="orders">
                                    {/* <div className="woocommerce-message"><a className="woocommerce-Button button" href="#">Go shop</a>No order has been made yet. 
                                    </div> */}
                                    <p className="dashboard-heading">
                                        My Orders
                                    </p>
                                    {!urlParams.orderId &&
                                    <Orders />}
                                    {urlParams.orderId &&
                                    <OrderDetail />}
                                </div> 
                                <div className={urlParams.id=='downloads' ? 'tab-pane fade active in' : 'tab-pane fade'} id="downloads">
                                    <p className="dashboard-heading">
                                        Orders
                                    </p>
                                    <div className="woocommerce-info"><a className="woocommerce-Button button" href="#">Go shop</a>No downloads available yet. 
                                    </div>
                                </div>                                      
                                <div className={urlParams.id=='addresses' ? 'tab-pane fade active in' : 'tab-pane fade'} id="addresses">    
                                    <p className="dashboard-heading">
                                        Checkout
                                    </p>                                
                                    <div className="woocommerce-MyAccount-content wd-myaccount-content-wrapper"> 
                                    <p>
                                        The following addresses will be used on the checkout page by default.</p>
                                        <div className="u-columns woocommerce-Addresses addresses">
                                            <div className="woocommerce-Address">
                                                <header className="woocommerce-Address-title title">
                                                    <h3>Billing address</h3>
                                                    <a href="#" className="edit">Edit</a>
                                                </header>
                                                <address>
                                                    You have not set up this type of address yet.       
                                                </address>
                                            </div>
                                            <div className="woocommerce-Address">
                                                <header className="woocommerce-Address-title title">
                                                    <h3>Shipping address</h3>
                                                    <a href="#" className="edit">Edit</a>
                                                </header>
                                                <address>
                                                    You have not set up this type of address yet.       
                                                </address>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={urlParams.id=='profile' ? 'tab-pane fade active in' : 'tab-pane fade'} id="profile">                                    
                                    <p className="dashboard-heading">
                                        Profile
                                    </p>
                                    <div className="woocommerce-MyAccount-content"> 
                                        {/* <header className="row woocommerce-Address-title title">
                                            <h3 className="col-xs-12 metro-title">ACCESS YOUR ACCOUNT</h3>
                                        </header> */}
                                        {formErrorMsg && formErrorMsg!='' &&
                                        <p className="col-xs-12 invalid-text">{formErrorMsg}</p>
                                        }
                                        <form onSubmit={(e)=> handleFormSubmission(e)} className="row woocommerce-EditAccountForm edit-account" action="" method="post">
                                            <p className="col-md-6 col-sm-12 woocommerce-form-row woocommerce-form-row--first form-row form-row-first">
                                                <input type="text" className="woocommerce-Input woocommerce-Input--text input-text" name="firstname" id="firstname" onChange={onFieldChange} value={profile.firstname} placeholder="First Name" />
                                            </p>
                                            <p className="col-md-6 col-sm-12 woocommerce-form-row woocommerce-form-row--last form-row form-row-last">
                                                <input type="text" className="woocommerce-Input woocommerce-Input--text input-text" name="lastname" id="lastname" onChange={onFieldChange} value={profile.lastname} placeholder="Last Name" />
                                            </p>
                                            <div className="clear"></div>
                                            <p className="col-xs-12 woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                                <input readonly type="email" className="woocommerce-Input woocommerce-Input--email input-text" name="account_email" id="account_email" value={profile.email} placeholder="Email address" />
                                            </p>
                                            <fieldset className="col-xs-12">
                                                <legend>Password change</legend>
                                                {/* <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                                    <label htmlFor="password_current">Current password (leave blank to leave unchanged)</label>
                                                    <input type="password" className="woocommerce-Input woocommerce-Input--password input-text" name="password_current" id="password_current" />
                                                </p> */}
                                                <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                                    <label htmlFor="password_1">New password (leave blank to leave unchanged)</label>
                                                    <input type="password" className="woocommerce-Input woocommerce-Input--password input-text" name="password_1" id="password_1" />
                                                </p>
                                                <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                                    <label htmlFor="password_2">Confirm new password</label>
                                                    <input type="password" className="woocommerce-Input woocommerce-Input--password input-text" name="password_2" id="password_2" />
                                                </p>
                                            </fieldset>
                                            <div className="clear"></div>
                                            <p className="col-xs-12">
                                                {/* <input type="hidden" id="_wpnonce" name="_wpnonce" value="96df2c51c6" /> */}
                                                {/* <input type="hidden" name="_wp_http_referer" value="/my-account/edit-account/" /> */}
                                                <input type="submit" className="woocommerce-Button button btn-shop-now-fill" name="save_account_details" value="Save changes" />
                                                {/* <input type="hidden" name="action" value="save_account_details" /> */}
                                            </p>
                                        </form>
                                    </div>
                                </div>
                                <div className={urlParams.id=='logout' ? 'tab-pane fade active in' : 'tab-pane fade'} id="logout">                                    
                                    <div className="woocommerce-message">Are you sure you want to log out? <a href="#">Confirm and log out</a></div>
                                    <div className="woocommerce-MyAccount-content wd-myaccount-content-wrapper">    
                                        <p>Hello <strong>user-name</strong> (not <strong>user-name</strong>? <a href="#">Log out</a>)</p>
                                        <p>From your account dashboard you can view your <a href="#">recent orders</a>, manage your <a href="#">shipping and billing addresses</a> and <a href="#">edit your password and account details</a>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>  
                </div>
            </div>
        </div>                
        
    )
}
export default MyAccount