let Services = {}
// let baseRoute = 'https://fakestoreapi.com/'
// let baseRoute = 'http://127.0.0.1:8000/api/v1/'
// let baseRoute = 'https://myprojectexcel.com/apis/v1/'
let baseRoute = 'https://www.ezowotools.com//apis/v1/'



var serializeJSON = function(data) {
  return Object.keys(data)
    .map(function(keyName) {
      return (
        encodeURIComponent(keyName) + '=' + encodeURIComponent(data[keyName])
      )
    })
    .join('&')
}

Services.getDataSimple = function(queryUrl, queryData) {
  const url = baseRoute + queryUrl + '?' + serializeJSON(queryData)
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      // usertoken: localStorage.access_token
    }
  })
}

Services.getData = (queryUrl, queryData) => {
  if (!navigator.onLine) {
    window.location.href = '/login'
  }

  let url = ''
  if (queryData === undefined) {
    url = baseRoute + queryUrl
  } else {
    url = baseRoute + queryUrl + '?' + serializeJSON(queryData)
  }
  let fetchPromise = fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      // usertoken: localStorage.access_token
    },

    method: 'GET'
  })

  let checkToken = fetchPromise
 
  fetchPromise.then(response =>  response.clone().json() )
  .then(response => {
    if(response && response.code == -1){
      localStorage.setItem('user_expired', 1);
      window.location.href = '/login'
    } else if(response && response.code == -2){
      localStorage.setItem('user_expired', 2);
      window.location.href = '/login'  
    }
  })

  return checkToken
}

Services.getDataWithToken = (queryUrl, queryData) => {
  if (!navigator.onLine) {
    window.location.href = '/login'
  }

  let url = ''
  if (queryData === undefined) {
    url = baseRoute + queryUrl
  } else {
    url = baseRoute + queryUrl + '?' + serializeJSON(queryData)
  }
  // url = ( url.indexOf('?')>-1 ? url : url+'?token='+localStorage.access_token )
  
  let fetchPromise = fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      token: localStorage.access_token
    },

    method: 'GET'
  })

  let checkToken = fetchPromise
 
  fetchPromise.then(response =>  response.clone().json() )
  .then(response => {
    if(response && response.code == -1){
      localStorage.setItem('user_expired', 1);
      window.location.href = '/login'
    } else if(response && response.code == -2){
      localStorage.setItem('user_expired', 2);
      window.location.href = '/login'  
    }
  })

  return checkToken
}

Services.postDataWithoutToken = function(queryUrl, queryData) {
  let url = baseRoute + queryUrl
  if(queryData.auto_login && queryData.auto_login===1){
    url+= (url.indexOf('?')>=0 ? '&':'?')+'auto_login=1'
  }
  return fetch(url, {
    headers: {
      // 'Content-Type': 'application/x-www-form-urlencoded'
      'Content-Type' : 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(queryData)
    // body: queryData
  })
}

Services.postData = function(queryUrl, queryData) {
  let url = baseRoute + queryUrl
  // url = ( url.indexOf('?')>-1 ? url : url+'?token='+localStorage.access_token )
  return fetch(url, {
    headers: {
      // 'Content-Type': 'application/x-www-form-urlencoded'
      'Content-Type' : 'application/json',
      token: localStorage.access_token
    },
    method: 'POST',
    body: JSON.stringify(queryData)
    // body: queryData
  })
}

Services.postFormData = function(queryUrl, queryData) {
  const url = baseRoute + queryUrl
  return fetch(url, {
    headers: {
      // 'Content-Type': 'application/x-www-form-urlencoded'
      'Content-Type' : 'application/json'
    },
    method: 'POST',
    body: serializeJSON(queryData)
    // body: queryData
  })
}

export default Services